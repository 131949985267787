import React from "react";
import styles from "./Legacy.module.css";
import { multipleClassName } from "../../lib/utils/helpers/helper";
import CustomImageComponent from "../common/CustomImage/CustomImageComponent";
import parse from 'html-react-parser';
import GDCSubmitButton from "../common/FixedDownloadButton/GDCSubmitButton";
import Slider, { Settings } from "react-slick";
import useIsMobile from "../../lib/customHooks/isMobile";

const Legacy = (props) => {
  const {isMobileDevice} = useIsMobile();
  const legacyData = props;

  return (
    <div className={styles.root}>
      <div className={styles.wedgeTop} />
      <div className={multipleClassName("container", styles.mainContainer)}>
        <div className={multipleClassName(styles.sectionCopyText)}>
          <div className={styles.waterMarkAndTextContainer}>
            <div className={styles.waterMarkNoWrap}>{legacyData.watermark}</div>
            <div className={styles.heading}>{legacyData.heading}</div>
            <div className={styles.subHeading}>
              {parse(legacyData.subHeading)}
            </div>
          </div>
        </div>
        <div>
          {legacyData.content.length > 2 || isMobileDevice ? (
            <ContentSlider data={legacyData} />
          ) : (
            legacyData.content.map((item) => {
              return (
                <div className={styles.contentContainer}>
                  <div className={styles.contentImg}>
                    <CustomImageComponent
                      layout="fill"
                      objectFit="cover"
                      alt={""}
                      src={item.img}
                    />
                  </div>
                  <div className={styles.contentTextContainer}>
                    <div className={styles.headingContainer}>
                      <div>{item.heading}</div>
                      <div className={styles.year}>{item.year}</div>
                    </div>
                    <p className={styles.para}>{item.para}</p>
                    <div style={{ width: "fit-content" }}>
                      <GDCSubmitButton
                        text={item.ctaText}
                        url={item.url}
                        sendAnalytics={() => {}}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      <div className={styles.wedgeBottom} />
    </div>
  );
};

const ContentSlider = (props) => {
  const sliderSettings: Settings = {
      centerMode: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      pauseOnHover: false,
      autoplay: false,
      speed: 500,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            centerPadding: "30px",
            slidesToShow: 1.1,
            variableWidth: false,
            arrows: true,
          },
        },
      ]
    };

  const data = props.data;
  return (
    <div className={styles.sliderContainer}>
        <Slider {...sliderSettings}>
          {data.content.map((item, index) => {
            return (
              <div className="tttmainSlideContainer">
                <div className={styles.tttmainSlideContainer}>
                  <div className={styles.slideContainer}>
                    <div className={styles.slideImage}>
                      <CustomImageComponent
                        layout="fill"
                        objectFit="cover"
                        objectPosition={"top"}
                        alt={""}
                        src={item.img}
                      />
                    </div>
                    <div className={styles.slideTextContainer}>
                      <div className={styles.headingContainer}>
                        {`${item.heading} ${item.year}`}
                      </div>
                      <div className={styles.para}>{item.para}</div>
                      <div className={styles.button1}>
                        <GDCSubmitButton
                          text={item.ctaTextMob}
                          url={item.url}
                          sendAnalytics={() => {}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
  )
}

export default Legacy;
