import React from "react";
import styles from "./OurPartners.module.css";
import parse from 'html-react-parser';
import { multipleClassName } from "../../lib/utils/helpers/helper";
import CustomImageComponent from "../common/CustomImage/CustomImageComponent";
import CustomLinkComponent from "../common/CusotmLink/CustomLinkComponent";


const OurPartners = (props) => {

  const partnersData = props;

  return (
    <div className={styles.root}>
      <div className={multipleClassName("container", styles.mainContainer)}>
        <div className={styles.waterMarkAndTextContainer}>
          <div className={styles.waterMarkNoWrap}>
            {partnersData.watermark}
          </div>
          <div className={styles.heading}>
            {partnersData.heading}
          </div>
          <div className={styles.subHeading}>
            {parse(partnersData.subHeading)}
          </div>
        </div>
      </div>
      <div
        className={multipleClassName("container", styles.imageContentConatiner)}
      >
        {partnersData.content.map((item, index) => {
          return (
            <div className={styles.imageContainer}>
              <div
                style={{ position: "relative", width: "90%", height: "100%"}}
              >
                <CustomImageComponent
                  layout="fill"
                  objectFit="contain"
                  alt={""}
                  src={item.img}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OurPartners;
