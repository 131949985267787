"use client"

import * as React from "react";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { IAppVersionData, IDeviceArchitechture } from "../types/common";
import { getLocaleFromPathname, getOnlineStatus, getUrlWithLocale } from "../utils/constants/PagesConstants";
import { getWebsiteEnvironment } from "../utils/constants/EnvironmentConstants";
import { BANNER_DOWNLOAD_BUTTON_TYPE, HOME_PAGE_URL } from "../utils/constants/GlobalConstants";
import { ABTestingData, BrandModelData } from "../types/Misc.type";
import { usePathname } from "next/navigation";
import { useApkUrl } from "../customHooks/useApkUrl";

type Props = {
    deviceArchitectureMapping: IDeviceArchitechture,
    locale: string,
    appVersions?: IAppVersionData,
    abTestingData: ABTestingData,
    brandModelData: BrandModelData,
    apkDisabled?: boolean
}

type CommonContextType = {
    downloadButtonState: DownloadButtonState,
    setDownloadButtonState: Function,
    countdown: number | null,
    startCountdown: Function,
    clickApkUrl: string,
    setClickApkUrl: Function,
    isNetworkToastOpen: boolean,
    setIsNetworkToastOpen: Function,
    handleHamburgerClick: Function,
    menuOpen: boolean,
    isAutoDownloadFlow: boolean,
    setIsAutoDownloadFlow: Function,
    clickedBtnType: string,
    setClickedBtnType: React.Dispatch<React.SetStateAction<string>>,
    isUtmGAW: boolean,
    setIsUtmGAW: React.Dispatch<React.SetStateAction<boolean>>,
    androidUrl: string,
    downloadUrl: string
} & Props

export enum DownloadButtonState {
    Initial,
    DownloadClicked,
    DownloadStart,
    Downloading,
    DownloadAgain
}

const commonContextDefault: CommonContextType = {
    downloadButtonState: DownloadButtonState.Initial,
    setDownloadButtonState: () => {
    },
    countdown: null,
    startCountdown: () => {
    },
    deviceArchitectureMapping: {},
    clickApkUrl: "",
    setClickApkUrl: () => {
    },
    isNetworkToastOpen: false,
    setIsNetworkToastOpen: () => {
    },
    locale: getWebsiteEnvironment().defaultLocale,
    handleHamburgerClick: () => {
    },
    menuOpen: false,
    isAutoDownloadFlow: false,
    setIsAutoDownloadFlow: () => {
    },
    clickedBtnType: BANNER_DOWNLOAD_BUTTON_TYPE,
    setClickedBtnType: (value) => value,
    abTestingData: {},
    brandModelData: {},
    isUtmGAW: false,
    setIsUtmGAW: (val) => val,
    androidUrl: "",
    downloadUrl: "",
}

export const CommonContextData = createContext<CommonContextType>(commonContextDefault)

export const useDownloadContext = () => {
    return useContext(CommonContextData)
}

export const CommonContext = (props: React.PropsWithChildren<Props>) => {
    const [downloadButtonState, setDownloadButtonState] = useState<DownloadButtonState>(DownloadButtonState.Initial)
    const [countdown, setCountdown] = useState<number | null>(null)
    const [clickApkUrl, setClickApkUrl] = useState<string>("")
    const [isNetworkToastOpen, setIsNetworkToastOpen] = useState<boolean>(false);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [isAutoDownloadFlow, setIsAutoDownloadFlow] = useState<boolean>(false);
    const [clickedBtnType, setClickedBtnType] = useState<string>(BANNER_DOWNLOAD_BUTTON_TYPE);
    const [isUtmGAW, setIsUtmGAW] = useState(false);

    const pathName = usePathname();
    const { pathNameWithoutLocale } = getLocaleFromPathname(pathName!);

    let pageName: string;
    if (pathNameWithoutLocale === HOME_PAGE_URL) {
        pageName = pathNameWithoutLocale;
    } else {
        const pageNameArray = pathNameWithoutLocale.split("/");
        pageName = pageNameArray[pageNameArray.length - 1];
    }

    const { downloadUrl, androidUrl } = useApkUrl({
        locale: props.locale,
        appVersions: props.appVersions!,
        pageName,
        deviceArchitectureMapping: props.deviceArchitectureMapping,
    });

    const deviceArchitectureMapping = props.deviceArchitectureMapping
    let timeout = useRef<NodeJS.Timeout | undefined>()

    useEffect(() => {
        if (downloadButtonState === DownloadButtonState.Downloading) {
            clearInterval(timeout.current)
        }
        if (downloadButtonState > DownloadButtonState.Initial) {
            setIsNetworkToastOpen(!getOnlineStatus())
        }
    }, [downloadButtonState]);

    const startCountdown = (counterFromNumber: number = 5) => {

        setCountdown(counterFromNumber)
        timeout.current = setInterval(() => {
            if (counterFromNumber >= 0) {
                --counterFromNumber
                setCountdown(counterFromNumber)
            } else {
                timeout && clearInterval(timeout.current)
            }
        }, 1000)
    }

    // Function to toggle side drawer menu state
    const handleHamburgerClick = (): void => {
        if (menuOpen) {
            setMenuOpen(false);
        } else {
            setMenuOpen(true);
        }
    };

    const value = {
        downloadButtonState,
        setDownloadButtonState,
        countdown,
        startCountdown,
        deviceArchitectureMapping,
        clickApkUrl,
        setClickApkUrl,
        isNetworkToastOpen,
        setIsNetworkToastOpen,
        locale: props.locale,
        handleHamburgerClick,
        menuOpen,
        isAutoDownloadFlow,
        setIsAutoDownloadFlow,
        clickedBtnType,
        setClickedBtnType,
        appVersions: props.appVersions,
        abTestingData: props.abTestingData,
        brandModelData: props.brandModelData,
        isUtmGAW,
        setIsUtmGAW,
        downloadUrl,
        androidUrl
    }

    return (
        <CommonContextData.Provider value={value}>
            {props.children}
        </CommonContextData.Provider>
    )
}