import React, { useRef, useState } from "react";
import styles from "./ParticipationProcess.module.css";
import { multipleClassName } from "../../lib/utils/helpers/helper";
import CustomImageComponent from "../common/CustomImage/CustomImageComponent";
import parse from "html-react-parser";
import GDCSubmitButton from "../common/FixedDownloadButton/GDCSubmitButton";
import useIsMobile from "../../lib/customHooks/isMobile";

const ParticipationProcess = (props) => {
  // const rootRef = useRef<HTMLDivElement>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  // function disableScroll() {
  //   document.body.addEventListener("wheel", preventDefault, { passive: false });
  //   document.body.addEventListener("touchmove", preventDefault, { passive: false });
  //   document.body.addEventListener("keydown", preventScrollKeys, { passive: false });
  // }

  // function enableScroll() {
  //   document.body.removeEventListener("wheel", preventDefault);
  //   document.body.removeEventListener("touchmove", preventDefault);
  //   document.body.removeEventListener("keydown", preventScrollKeys);
  // }

  // function preventDefault(event) {
  //   event.preventDefault();
  // }

  // function preventScrollKeys(event) {
  //   const keys = ["ArrowUp", "ArrowDown", "Space", "PageUp", "PageDown", "Home", "End"];
  //   if (keys.includes(event.key)) {
  //     event.preventDefault();
  //   }
  // }

  // function freeze() {
  //   console.log(">>>>>>>>>>>>>>>>>frezz");
  //   let top= window.scrollY;
  
  //   document.body.style.overflow= 'hidden';
  
  //   window.onscroll= function() {
  //     window.scroll(0, top);
  //   }
  // }
  
  // function unfreeze() {
  //   console.log(">>>>>>>>>>>>>>>>>unfrezz");
  //   document.body.style.overflow= '';
  //   window.onscroll= null;
  // }

  // const handleScroll = (e) => {

  //   let box = rootRef.current;
  //   if (currentSlide >= 2){
  //     window.onscroll = null;
  //     setTimeout(() => {
  //       setCurrentSlide(0);
  //     }, 2000);
  //     return ;
  //   }

  //   var boxTop = box.getBoundingClientRect().top + window.scrollY - 50;
  //   window.onscroll = function () { window.scrollTo(0, boxTop); };

  //   let timer: NodeJS.Timeout;
  //   timer = setTimeout(() => {
  //     setCurrentSlide((currentSlide+1));
  //     clearTimeout(timer);
  //   }, 1000);
  // }

  const data = props;

  return (
    // <div className={styles.root} onWheel={handleScroll} ref={rootRef}>
    <div className={styles.root}>
      <div className={styles.wedgeTop} />
      <div className={multipleClassName("container", styles.mainContainer)}>
        <div className={styles.aboutUs}>
          <div className={multipleClassName(styles.sectionCopyText)}>
            <div className={styles.waterMarkAndTextContainer}>
              <div className={styles.waterMarkNoWrap}>{data.watermark}</div>
              <div className={styles.heading}>{data.heading}</div>
              <div className={styles.subHeading}>{parse(data.subHeading)}</div>
            </div>
          </div>
          <div className={styles.bannerButton}>
            <GDCSubmitButton
              text={data.buttonData.text}
              url={data.buttonData.url}
              disabled={data.buttonData.disabled}
              sendAnalytics={() => {}}
            />
          </div>
        </div>
        <div className={styles.contentsContainer}>
          <Navigator
            currentSlide={currentSlide}
            data={data}
            setCurrentSlide={setCurrentSlide}
          />
          <Content currentSlide={currentSlide} data={data} />
        </div>
        <div className={styles.bannerButton2}>
          <GDCSubmitButton
            text={data.buttonData.text}
            url={data.buttonData.url}
            disabled={data.buttonData.disabled}
            sendAnalytics={() => {}}
          />
        </div>
      </div>
      <div className={styles.wedgeBottom} />
    </div>
  );
};

function Navigator(props: any) {
  const {isMobileDevice} = useIsMobile();
  const { currentSlide, data, setCurrentSlide } = props;
  const handleSlideChange = (index: number) => {
    setCurrentSlide(index);
  };
  return (
    <div className={styles.navigationContainer}>
      {data.navigationData.map((item: any, index: any) => {
        return (
          <div
            onClick={() => {
              handleSlideChange(index);
            }}
            className={index === currentSlide ? styles.navigation : ""}
            style={{ padding: isMobileDevice ? "2% 3%" : "3.3% 2% 3.3% 5%", cursor: "pointer" }}
          >
            <div
              className={
                index === currentSlide
                  ? styles.navigationTitleSelected
                  : styles.navigationTitle
              }
            >
              {isMobileDevice ? item.titleMob : item.title}
            </div>
          </div>
        );
      })}
    </div>
  );
}

function Content(props: any) {
  const {ecData, csData, epData} = props.data;

  const EligibilityCriteria = (
    <div className={styles.ecContents}>
      {ecData.map((item) => {
        return (
          <div className={styles.eligibilityCriteriaContent}>
            <div className={styles.ecImg}>
              <CustomImageComponent
                layout="fill"
                objectFit="contain"
                alt={""}
                src={item.img}
              />
            </div>
            <div>
              <div className={styles.ecHeading}>{item.heading}</div>
              <div className={styles.ecPara}>{item.para}</div>
            </div>
          </div>
        );
      })}
    </div>
  );

  const ContestStages = (
    <div>
      <div className={styles.csContentDesktop}>
        <div className={styles.csContents}>
          {csData.map((item, index) => {
            return (
              <div
                style={{
                  position: "relative",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "40%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <CustomImageComponent
                      layout="fill"
                      objectFit="contain"
                      alt={""}
                      src={item.img}
                    />
                  </div>
                </div>
                <div style={{ marginTop: "10%" }}>
                  <div className={styles.csContentDate}>{item.date}</div>
                  <div className={styles.csContentHeading}>
                    {item.heading}
                  </div>
                  <div className={styles.csContentPara}>{item.para}</div>
                </div>
                {index !== 3 && (
                  <div
                    style={{
                      position: "absolute",
                      right: "calc(clamp(20px, 2vw, 50px) * -1)",
                      bottom: 80,
                      width: "35%",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        opacity: 0.3,
                      }}
                    >
                      <CustomImageComponent
                        layout="fill"
                        objectFit="contain"
                        alt={""}
                        src={
                          "https://d3g4wmezrjkwkg.cloudfront.net/website/images/ttt-revamp/timelines-arrow.webp"
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.contentMobile}>
        <div className={styles.csContentsContainer}>
          {csData.map((item, index) => {
            return (
              <div className={styles.csContentMobile}>
                <div
                  style={{
                    position: "relative",
                    justifyContent: "space-evenly",
                    height: "clamp(70px, 9.04vw, 200px)",
                    display: "grid",
                    gridTemplateColumns: "1fr 3fr",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "80%",
                    }}
                  >
                    <div
                      style={{
                        width: "75%",
                        height: "100%",
                        position: "relative",
                      }}
                    >
                      <CustomImageComponent
                        layout="fill"
                        objectFit="contain"
                        objectPosition={"left"}
                        alt={""}
                        src={item.img}
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className={styles.csContentHeading}>
                        {item.heading}
                      </div>
                      <div className={styles.csContentDate}>{item.date}</div>
                    </div>
                    <div className={styles.csContentPara}>{item.para}</div>
                  </div>
                </div>
                <div>
                  {index !== 3 && (
                    <div
                      style={{
                        right: "calc(clamp(20px, 10.85vw, 200px) * -1)",
                        bottom: 30,
                        width: "100%",
                        height: "20px",
                        paddingLeft: 7,
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "40%",
                          height: "50%",
                          opacity: 0.3,
                          transform: "rotate(90deg)",
                          marginLeft: "-13%"
                        }}
                      >
                        <CustomImageComponent
                          layout="fill"
                          objectFit="contain"
                          objectPosition={"bottom"}
                          alt={""}
                          src={
                            "https://d3g4wmezrjkwkg.cloudfront.net/website/images/ttt-revamp/timelines-arrow.webp"
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  const EvaluationParameter = (
    <div className={styles.epContents}>
      {epData.map((item) => {
        return (
          <div className={styles.epContent}>
            <div className={styles.epImg}>
              <CustomImageComponent
                layout="fill"
                objectFit="contain"
                alt={""}
                src={item.img}
              />
            </div>
            <div>
              <div className={styles.epHeading}>{item.heading}</div>
              <div className={styles.epPara}>{item.para}</div>
            </div>
          </div>
        );
      })}
    </div>
  );

  if (props.currentSlide === 0)
    return EligibilityCriteria;
  else if (props.currentSlide === 1)
    return ContestStages;
  return EvaluationParameter;
}

export default ParticipationProcess;
