import React, { useRef, useState } from "react";
import styles from "./Jury.module.css";
import parse from 'html-react-parser';
import { multipleClassName } from "../../lib/utils/helpers/helper";
import CustomImageComponent from "../common/CustomImage/CustomImageComponent";
import Slider, { Settings } from "react-slick";
import useIsMobile from "../../lib/customHooks/isMobile";
import CustomCarousel from "../common/CustomCarouselNaviagtion/CustomCarouselNavigation";

const Jury = (props) => {
  const { isMobileDevice } = useIsMobile();
  const [nextSlide, setNextSlide] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider>(null);

  const sliderSettings: Settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: isMobileDevice ? 2.2 : 4.9,
    slidesToScroll: 1,
    centerPadding: "0px",
    dots: false,
    arrows: false,
    pauseOnHover: false,
    autoplay: true,
    speed: 500,
    beforeChange: (currentSlide, nextSlide) => {
      setNextSlide(nextSlide);
    },
    afterChange: (currentSlide) => {
      setCurrentSlide(currentSlide);
    },
  };

  const juryData = props;

  return (juryData && juryData.showJury &&
    <div className={styles.root}>
      <div className={multipleClassName("container", styles.mainContainer)}>
        <div className={styles.waterMarkAndTextContainer}>
          <div className={styles.waterMarkNoWrap}>
            {juryData.watermark}
          </div>
          <div className={styles.heading}>
            {juryData.heading}
          </div>
          <div className={styles.subHeading}>
            {parse(juryData.subHeading)}
          </div>
        </div>
      </div>
      <div
        className={multipleClassName(styles.sliderContainer)}
      >
        <Slider {...sliderSettings} ref={sliderRef}>
          {juryData.content.map((item, index) => {
            return (
              <>
                <div
                  key={index}
                  className={styles.slide}
                  style={{
                    position: "relative",
                  }}
                >
                  <CustomImageComponent
                    layout="fill"
                    objectFit="contain"
                    objectPosition={"top"}
                    alt={""}
                    src={item.img}
                  />
                  <div className={styles.juryDetailsContainer}>
                    <div className={styles.juryName}>{item.juryInfo.name}</div>
                    <div className={styles.juryInfoContainer}>
                      <div className={styles.juryInfo}>{parse(isMobileDevice ? item.juryInfo.infoMobile : item.juryInfo.info)}
                      </div>
                      <div className={styles.juryInfoImg}>
                        <CustomImageComponent 
                          layout="fill"
                          objectFit="contain"
                          objectPosition={"left"}
                          alt={""} 
                          src={item.juryInfo.img}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </Slider>
      </div>
      <div className={styles.CustomCarouselNaviagtion}>
        <CustomCarousel
          nextSlide={nextSlide}
          currentSlide={currentSlide}
          slideCount={juryData.content.length}
          sliderRef={sliderRef}
          hideArrow={true}
          customDotColor="#280042"
        />
      </div>
      <div className={styles.wedgeBottom} />
    </div>
  );
};

export default Jury;
