import React from "react";
import styles from "./AboutUs.module.css";
import { multipleClassName } from "../../lib/utils/helpers/helper";
import CustomImageComponent from "../common/CustomImage/CustomImageComponent";
import parse from 'html-react-parser';

const AboutUs = (props) => {
  const aboutUsData = props;
  return (
    <div className={styles.root}>
      <div className={styles.wedgeTop} />
      <div className={multipleClassName("container", styles.mainContainer)}>
        <div className={styles.waterMarkAndTextContainer}>
          <div className={styles.waterMarkNoWrap}>{aboutUsData.watermark}</div>
          <div className={styles.heading}>{aboutUsData.heading}</div>
          <div className={styles.subHeading}>
            {parse(aboutUsData.subHeading)}
          </div>
        </div>
        <div className={styles.aboutUs}>
          <div className={multipleClassName(styles.sectionCopyText)}>
            <div className={styles.logoConatiner}>
              <CustomImageComponent
                layout="fill"
                objectFit="contain"
                objectPosition={"left"}
                alt={""}
                src={aboutUsData.logo}
              />
            </div>
            <div className={styles.para1}>{aboutUsData.para1}</div>
            <div
              style={{
                margin: "3% 0",
                height: 1,
                backgroundImage:
                  "linear-gradient(to right, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)",
              }}
            />
            <div className={styles.paraHeadingContainer}>
              {aboutUsData.status && (
                <div className={styles.statusButton}>
                  <div className={styles.dot} />
                  <div className={styles.statusText}>{aboutUsData.status}</div>
                </div>
              )}
              <div className={styles.paraHeading}>
                {aboutUsData.paraHeading}
              </div>
              {aboutUsData.status && (
                <div className={styles.statusButtonMobile}>
                  <div className={styles.dot} />
                  <div className={styles.statusText}>{aboutUsData.status}</div>
                </div>
              )}
            </div>
            <div className={styles.para2}>{aboutUsData.para2}</div>
          </div>
          <div className={styles.aboutUSImageContainer}>
            <CustomImageComponent
              src={aboutUsData.image}
              alt={"aboutUsImage"}
              layout="fill"
              objectFit="contain"
            />
          </div>
        </div>
      </div>
      <div className={styles.wedgeBottom} />
    </div>
  );
};

export default AboutUs;
