import Slider, { Settings } from "react-slick";
import Analytics from "../../lib/analytics/Analytics";
import { getGDCPageEvents } from "../../lib/analytics/AnalyticsEvents";
import useIsMobile from "../../lib/customHooks/isMobile";
import CustomImageComponent from "../common/CustomImage/CustomImageComponent";
import GDCSubmitButton from "../common/FixedDownloadButton/GDCSubmitButton";
import Marquee from "../common/Marquee/Marquee";
import styles from "./BannerComponent.module.css";
import parse from "html-react-parser"
import { multipleClassName } from "../../lib/utils/helpers/helper";
import { useEffect, useRef, useState } from "react";

const BannerComponent = (props) => {
    const { isMobileDevice } = useIsMobile();
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderTime = 5000;
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const [hover, setHover] = useState(false);
    const [timeStamps, setTimeStamps] = useState<Date[]>([]);
    const addTimeStamp = () => setTimeStamps([...timeStamps, new Date()]);
    const { bannersData } = props;
    const sendAnalyticsGDCHedaer = () => {
      Analytics.getInstance().logEvent(getGDCPageEvents('Header_Submit_Clicked'));
    }

    const settings: Settings = {
      infinite: bannersData.length > 1,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      centerMode: false,
      variableWidth: false,
      autoplay: true,
      autoplaySpeed: 5000,
      beforeChange: (oldSlide: number, nextSlide: number) => {
        setTimeStamps([new Date()]);
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
          sliderRef.current?.slickNext();
        }, sliderTime);
  
        setCurrentSlide(nextSlide);
      },
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: true,
            className: "tttBannerDotsClass",
          },
        },
      ],
    };
    const sliderRef = useRef<Slider>(null);

    const getElapsedTime = () => {
      if (timeStamps.length <= 0) {
        return 0;
      }
      let elapsedTime =
        (timeStamps.at(-1)!.getTime() - timeStamps[0].getTime()) % sliderTime;
      return elapsedTime;
    };

    useEffect(() => {
      let animatingDiv1 = document.getElementById(
        "businessLandingPageAnimation1"
      );
      let animatingDiv2 = document.getElementById(
        "businessLandingPageAnimation2"
      );
      if (hover) {
        addTimeStamp();
        if (timerRef.current) clearTimeout(timerRef.current);
        if (animatingDiv1 && animatingDiv2) {
          animatingDiv1.classList.add(styles.animationPause);
          animatingDiv2.classList.add(styles.animationPause);
        }
      } else {
        if (animatingDiv1 && animatingDiv2) {
          animatingDiv1.classList.remove(styles.animationPause);
          animatingDiv2.classList.remove(styles.animationPause);
          const elapsedTime = getElapsedTime();
          let remainingTime = Math.abs(sliderTime - elapsedTime);
          timerRef.current = setTimeout(() => {
            sliderRef.current?.slickNext();
          }, remainingTime);
        }
      }
    }, [hover]);
    
    return (
      <div className={styles.root}>
        <Slider {...settings} ref={sliderRef}>
          {bannersData.map((bannerData, index) => {
            return (
              <div
                className={styles.mainContainer}
                onMouseEnter={() => {
                  setHover(true);
                }}
                onMouseLeave={() => {
                  setHover(false);
                }}
              >
                <div className={styles.bannerContainer}>
                  <CustomImageComponent
                    layout="fill"
                    objectFit="cover"
                    alt={"banner"}
                    src={
                      isMobileDevice ? bannerData.bgImgMobile : bannerData.bgImg
                    }
                  />
                </div>
                <div className="container">
                  <div className={styles.waterMarkAndTextContainer}>
                    <div className={styles.waterMarkNoWrap}>
                      {bannerData.watermark}
                    </div>
                    <div className={styles.bannerHeading}>
                      {isMobileDevice
                        ? parse(bannerData.headingMob)
                        : parse(bannerData.heading)}
                    </div>
                  </div>
                  {bannerData.logo && (
                    <div className={styles.bannerLogo}>
                      <CustomImageComponent
                        layout="fill"
                        objectFit="contain"
                        objectPosition={"left"}
                        alt={"banner"}
                        src={bannerData.logo}
                      />
                    </div>
                  )}
                  {bannerData.para2 && (
                    <div className={styles.para2}>
                      {parse(bannerData.para2)}
                    </div>
                  )}
                  {bannerData.partnersData && (
                    <div className={styles.partnerContainer1}>
                      <div className="container">
                        <div className={styles.partnerHeading}>
                          {bannerData.partnersData.heading}
                        </div>
                        {isMobileDevice && (
                          <div className={styles.partnerLogoContainer}>
                            <Marquee>
                              {bannerData.partnersData.logo.map((item) => {
                                return (
                                  <div className={styles.partnerLogo}>
                                    <CustomImageComponent
                                      layout="fill"
                                      objectFit="contain"
                                      objectPosition={"center"}
                                      alt={"partnerLogo"}
                                      src={item.src}
                                    />
                                  </div>
                                );
                              })}
                            </Marquee>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div style={{ display: "flex" }}>
                    <div className={styles.bannerButton}>
                      <GDCSubmitButton
                        text={bannerData.buttonData.text}
                        url={bannerData.buttonData.url}
                        sendAnalytics={sendAnalyticsGDCHedaer}
                        disabled={bannerData.buttonData.disabled}
                      />
                    </div>
                    {!bannerData.buttonData2.hidden && (
                      <div className={styles.bannerButton}>
                        <GDCSubmitButton
                          text={bannerData.buttonData2.text}
                          url={bannerData.buttonData2.url}
                          sendAnalytics={sendAnalyticsGDCHedaer}
                          isSecondary={true}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {bannerData.partnersData && (
                  <div className={styles.partnerContainer}>
                    <div className="container">
                      <div className={styles.partnerHeading}>
                        {bannerData.partnersData.heading}
                      </div>
                      {!isMobileDevice && (
                        <div className={styles.partnerLogoContainer}>
                          {bannerData.partnersData.logo.map((item) => {
                            return (
                              <div className={styles.partnerLogo}>
                                <CustomImageComponent
                                  layout="fill"
                                  objectFit="contain"
                                  objectPosition={"center"}
                                  alt={"partnerLogo"}
                                  src={item.src}
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </Slider>
        <Navigator
          currentSlide={currentSlide}
          data={bannersData}
          setCurrentSlide={setCurrentSlide}
          sliderRef={sliderRef}
        />
        <div className={styles.areaBelowNavigator}></div>
      </div>
    );
}

function Navigator(props: any) {
  const { currentSlide, data, setCurrentSlide, sliderRef } = props;
  const handleSlideChange = (index: number) => {
    setCurrentSlide(index);
    sliderRef.current?.slickGoTo(index);
  };
  return (
    <div className={styles.navigationContainer}>
      <div className={multipleClassName(styles.navigation)}>
        {data.map((item: any, index: any) => {
          const { text, title } = item.navigation;
          const color = currentSlide === index ? "#fff" : "#766a83";

          return (
            <div
              className={styles.box}
              onClick={() => {
                handleSlideChange(index);
              }}
            >
              <div className={styles.boxChild}>
                <h4 style={{ color }}>{title}</h4>
                <p style={{ color }}>{text}</p>
              </div>
              {currentSlide === index && (
                <>
                  <div
                    className={styles.borderDiv}
                    id="businessLandingPageAnimation1"
                  />
                  <div
                    className={styles.overlayDiv}
                    id="businessLandingPageAnimation2"
                  />
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BannerComponent;