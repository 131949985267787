"use client";
import React, { useRef, useState } from "react";
import styles from "./PreviousWinners.module.css";
import HTMLReactParser from "html-react-parser/lib/index";
import Slider, { Settings } from "react-slick";
import { multipleClassName } from "../../lib/utils/helpers/helper";
import CustomImageComponent from "../common/CustomImage/CustomImageComponent";
import CustomCarousel from "../common/CustomCarouselNaviagtion/CustomCarouselNavigation";
import parse from "html-react-parser";
import CustomLinkComponent from "../common/CusotmLink/CustomLinkComponent";
import GDCSubmitButton from "../common/FixedDownloadButton/GDCSubmitButton";

const PreviousWinners = (props) => {
  const [nextSlide, setNextSlide] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider>(null);

  const previousWinnersData = props;

  const sliderSettings: Settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    pauseOnHover: false,
    autoplay: false,
    speed: 500,
    beforeChange: (currentSlide, nextSlide) => {
      setNextSlide(nextSlide);
    },
    afterChange: (currentSlide) => {
      setCurrentSlide(currentSlide);
    },
    responsive: [
      {
        breakpoint: 767,
        settings: {
          centerMode: true,
          centerPadding: "60px",
          slidesToShow: 1,
          variableWidth: false,
          arrows: true,
        },
      },
    ],
  };

  return (
    <div className={styles.root}>
      <div className={multipleClassName("container", styles.mainContainer)}>
        <div className={styles.waterMarkAndTextContainer}>
          <div className={styles.waterMarkNoWrap}>
            {previousWinnersData.watermark}
          </div>
          <div className={styles.heading}>{previousWinnersData.heading}</div>
          <div className={styles.subHeading}>
            {HTMLReactParser(previousWinnersData.subHeading)}
          </div>
        </div>
      </div>
      {previousWinnersData.description && (
        <div className={multipleClassName("container", styles.description)}>
          {parse(previousWinnersData.description)}
        </div>
      )}
      <div className={styles.sliderContainer}>
        <Slider {...sliderSettings} ref={sliderRef}>
          {previousWinnersData.content.map((item, index) => {
            return (
              <div className="tttmainSlideContainer">
                <div className={styles.tttmainSlideContainer}>
                  <div className={styles.slideContainer}>
                    <div className={styles.slideImage}>
                      <CustomImageComponent
                        layout="fill"
                        objectFit="cover"
                        objectPosition={"top"}
                        alt={""}
                        src={item.img}
                      />
                      <div className={styles.seasonImgContainer}>
                        <div
                          style={{
                            position: "relative",
                            width: "55%",
                            height: 50,
                          }}
                        >
                          <CustomImageComponent
                            layout="fill"
                            objectFit="contain"
                            objectPosition={"left"}
                            alt={""}
                            src={item.seasonImg}
                            style={{ width: "50%", height: "auto" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.slideTextContainer}>
                      <div className={styles.headingContainer}>
                        <div className={styles.prizeSlideHeading}>
                          {item.heading}
                        </div>
                        <div className={styles.flagContainer}>
                          <CustomImageComponent
                            layout="fill"
                            objectFit="contain"
                            alt={""}
                            src={item.flagImg}
                          />
                        </div>
                      </div>
                      <p className={styles.prizeSlidePara}>{item.para}</p>
                      <CustomLinkComponent href={item.url}>
                        <div className={styles.readMoreContainer}>
                          <div>{previousWinnersData.readMoretext}</div>
                          <div className={styles.arrowContainer}>
                            <CustomImageComponent
                              layout="fill"
                              objectFit="contain"
                              alt={""}
                              src={
                                "https://d3g4wmezrjkwkg.cloudfront.net/website/images/superstarRevamp/resources-read-more-arrow.webp"
                              }
                            />
                          </div>
                        </div>
                      </CustomLinkComponent>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className={styles.CustomCarouselNaviagtion}>
        <CustomCarousel
          nextSlide={nextSlide}
          currentSlide={currentSlide}
          slideCount={previousWinnersData.content.length}
          sliderRef={sliderRef}
          hideArrow={true}
        />
      </div>
      <div className={styles.button}>
        <GDCSubmitButton
          text={ previousWinnersData.buttonData?.text }
          url={previousWinnersData.buttonData?.url}
          sendAnalytics={() => {}}
        />
      </div>
    </div>
  );
};

export default PreviousWinners;
