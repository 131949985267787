"use client";
import React, { useRef, useState } from "react";
import styles from "./ThePrize.module.css";
import HTMLReactParser from "html-react-parser/lib/index";
import Slider, { Settings } from "react-slick";
import { multipleClassName } from "../../lib/utils/helpers/helper";
import CustomImageComponent from "../common/CustomImage/CustomImageComponent";
import CustomCarousel from "../common/CustomCarouselNaviagtion/CustomCarouselNavigation";
import useIsMobile from "../../lib/customHooks/isMobile";

const ThePrize = (props) => {
  const { isMobileDevice } = useIsMobile();
  const [nextSlide, setNextSlide] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider>(null);

  const prizeData = props;

  const sliderSettings: Settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: isMobileDevice ? 2.1 : 3,
    slidesToScroll: 1,
    centerPadding: "0px",
    dots: false,
    arrows: false,
    pauseOnHover: false,
    autoplay: true,
    speed: 500,
    beforeChange: (currentSlide, nextSlide) => {
      setNextSlide(nextSlide);
    },
    afterChange: (currentSlide) => {
      setCurrentSlide(currentSlide);
    },
  };
  return (
    <div className={styles.root}>
      <div className={multipleClassName("container", styles.mainContainer)}>
        <div className={styles.waterMarkAndTextContainer}>
          <div className={styles.waterMarkNoWrap}>{prizeData.watermark}</div>
          <div className={styles.heading}>{prizeData.heading}</div>
          <div className={styles.subHeading}>
            {HTMLReactParser(prizeData.subHeading)}
          </div>
        </div>
      </div>
      {/* <div className={styles.sliderContainer}>
        <Slider {...sliderSettings} ref={sliderRef}>
          {prizeData.content.map((item, index) => {
            return (
              <>
                <div
                  key={index}
                  className={styles.slide}
                  style={{ width: "80%" }}
                >
                  <div className={styles.prizeSlide}>
                    <div className={styles.prizeSlideBg} />
                    <div className={styles.prizeImage}>
                      <CustomImageComponent
                        layout="fill"
                        objectFit="contain"
                        objectPosition={"top"}
                        alt={""}
                        src={item.img}
                      />
                    </div>
                    <div style={{ padding: "15px 15px 20px" }}>
                      <div className={styles.prizeSlideHeading}>
                        {item.heading}
                      </div>
                      <div className={styles.prizeSlidePara}>
                        {item.para}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </Slider>
      </div> */}
      <div className={styles.sliderContainer}>
        <Slider {...sliderSettings} ref={sliderRef}>
          {prizeData.content.map((item, index) => {
            return (
              <div>
                <div className={styles.slideImage}>
                  <CustomImageComponent
                    layout="fill"
                    objectFit="cover"
                    objectPosition={"bottom"}
                    alt={""}
                    src={item.img}
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
                <div className={styles.slideTextContainer}>
                  <div className={styles.prizeSlideHeading}>{item.heading}</div>
                  <div className={styles.prizeSlidePara}>{item.para}</div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className={styles.CustomCarouselNaviagtion}>
        <CustomCarousel
          nextSlide={nextSlide}
          currentSlide={currentSlide}
          slideCount={prizeData.content.length}
          sliderRef={sliderRef}
          hideArrow={isMobileDevice}
        />
      </div>
    </div>
  );
};

export default ThePrize;
